<template>
	<div class="wrapper" :class="{'active' : active}">
		<div class="button" @click="scrollTop">
			<span>ᐱ</span>
		</div>
	</div>
</template>

<script>
    export default {
        name: "ScrollBack",
		created() {
            window.addEventListener('scroll', this.handleScroll);
        },
		destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
		data() {
            return {
                active: false,
				multiplier: 0.5,
			};
		},
		methods: {
            handleScroll() {
                this.active = (window.scrollY > this.multiplier * window.innerHeight);
			},
			scrollTop() {
                window.scroll({
					top: 0,
					behavior: 'smooth',
                });
			}
		},
    };
</script>

<style lang="scss" scoped>
	.wrapper {
		width: 100%;
		position: fixed;
		bottom: 0;
		display: flex;
		justify-content: flex-end;
		opacity: 0;
		transition: opacity 0.3s ease;

		&.active {
			opacity: 1;
		}
	}

	.button {
		background: $yellow;
		display: flex;
		justify-content: center;
		cursor: pointer;
		transition: 0.3s ease;
		border: 1px solid #fff;
		color: #000;
		margin: 0.75rem;
		height: 2rem;
		width: 2rem;
		opacity: 0.5;

		@include media-breakpoint-up(lg) {
			margin: 3rem;
		}

		&:hover {
			opacity: 1;
		}

		span {
			align-self: center;
		}
	}
</style>
