import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

//Bootstrap
import { NavbarPlugin } from 'bootstrap-vue'
Vue.use(NavbarPlugin)

//Router transitions
import VuePageTransition from "vue-page-transition";
Vue.use(VuePageTransition);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
