<template>
    <div class="navigation">
        <div class="container">
            <b-navbar toggleable="md">
                <router-link class="navbar-brand" to="/">{{ $t('me.fullName') }}</router-link>
                <b-navbar-toggle target="nav-collapse">
                    <span class="icon-bar d-block top-bar"></span>
                    <span class="icon-bar d-block middle-bar"></span>
                    <span class="icon-bar d-block bottom-bar"></span>
                </b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item to="/">{{ $t('nav.home') }}</b-nav-item>
                        <!--<b-nav-item to="/cv">{{ $t('nav.cv') }}</b-nav-item>
                        <b-nav-item to="/about">{{ $t('nav.about') }}</b-nav-item>-->
                    </b-navbar-nav>
                    <b-navbar-nav class="right flex-row" ref="languages">
                        <b-nav-item href="#" v-for="lang in this.languages" :key="lang" :ref="lang" @click="changeLanguage(lang)">{{ lang.toUpperCase() }}</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </div>
</template>

<script>
    import eventBus from '../eventBus.js';
    export default {
        name: "Navigation",
        components: {},
        data() {
            return {
                languages: ['pl', 'en']
            };
        },
        mounted() {
            if(!this.languages.includes(this.$i18n.locale)) this.$i18n.locale = this.$i18n.fallbackLocale;
            this.$refs[this.$i18n.locale][0].classList.add('active');
        },
        methods: {
            changeLanguage(newLanguage) {
                if(this.languages.includes(newLanguage) === false) return;
                const oldLanguage = this.$i18n.locale;

                this.$refs[oldLanguage][0].classList.remove('active');
                this.$refs[newLanguage][0].classList.add('active');

                this.$i18n.locale = newLanguage;
                localStorage.setItem('language', newLanguage);

                eventBus.$emit('language', {from: oldLanguage, to: newLanguage});
            }
        }
    };
</script>

<style lang="scss" scoped>
    .navigation {
        border-bottom: 1px solid $yellow;
    }

    .navbar-brand {
        font-size: 1.25rem;

        @include media-breakpoint-up(lg) {
            font-size: 1.5rem;
        }
    }

    .navbar {
        padding: 0.75rem 1rem;

        @include media-breakpoint-up(lg) {
            padding: 1.25rem 0;
        }
    }

    .navbar-toggler {
        border: none;
        background: transparent !important;
        margin-right: -0.75rem;

        &:focus {
            outline: none;
        }

        /*
          Hamburder menu animation: http://kylegoslan.co.uk/bootstrap-4-hamburger-menu-animation/
        */

        .icon-bar {
            width: 22px;
            height: 2px;
            background-color: $yellow;
            transition: 0.3s ease;
            margin-top: 4px;

            &.top-bar {
                transform: rotate(45deg);
                transform-origin: 10% 10%;
            }

            &.middle-bar {
                opacity: 0;
            }

            &.bottom-bar {
                transform: rotate(-45deg);
                transform-origin: 10% 90%;
            }
        }

        &.collapsed {
            .icon-bar {
                &.top-bar {
                    transform: rotate(0);
                }

                &.middle-bar {
                    opacity: 1;
                }

                &.bottom-bar {
                    transform: rotate(0);
                }
            }
        }
    }

    .navbar-brand {
        margin-right: 1rem;

        @include media-breakpoint-up(lg) {
            margin-right: 2rem;
        }
    }

    ul li {
        margin-right: 1rem !important;

        a {
            padding: 0.25rem 0.825rem !important;
            border: 1px solid transparent;
            border-bottom-color: $yellow;
            color: $dark !important;
            transition: 0.3s ease;


            &:last-child {
                margin-right: 0;
            }

            &:hover {
                border-color: $yellow;
                text-decoration: none;
            }

            &.router-link-exact-active {
                border-color: $yellow;
                background-color: $yellow;
            }
        }
    }

    .nav-item {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .right {
        justify-content: space-evenly;
        margin-top: 0.5rem;

        @include media-breakpoint-up(md) {
            flex-grow: 1;
            justify-content: flex-end;
            margin: 0;
        }

        li {

            padding: 0 0.5rem;
            margin: 0;

            @include media-breakpoint-up(md) {
                padding: 0 0.25rem;
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            &.active a {
                border-color: $yellow;
                background-color: $yellow;
            }

            a {
                transition: 0.3s ease;
            }
        }
    }
</style>
