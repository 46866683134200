<template>
    <div class="terminal" :class="[state, disableTransition]" ref="terminal" @click="focusInput">

        <div class="bar">
            <div class="icons">
                <span class="icon icon-closed" @click="toggleState('closed')"></span>
                <span class="icon icon-maximised" @click="toggleState('maximised')"></span>
                <span class="icon icon-tray" @click="toggleState('tray')"></span>
            </div>
            <span class="title" @click="toggleState('')">{{ $t('terminal.window') }}</span>
        </div>

        <div class="content">

            <section ref="current" class="open"></section>

            <section ref="welcome" id="welcome">
                <p>
                    {{ $t('terminal.loginAs', {user: $t('terminal.guest')}) }}<br>
                    <span v-if="lastVisit && lastVisit.valueOf() > 0">{{ $t('terminal.lastLogin', {date: lastVisit.toUTCString()}) }}</span>
                </p>
                <p v-html="$t('terminal.initialMessage')"></p>
            </section>

            <section ref="commandError" id="error">
                <p v-html="$t('terminal.incorrectCommand') + ' ' + $t('terminal.initialMessage')"></p>
            </section>

            <section ref="commandHelp" id="help">
                <p v-html="$t('terminal.help').join('\n')"></p>
            </section>

            <section ref="commandEval" id="eval">
                <p ref="commandEvalP"></p>
            </section>

            <div class="command" :class="inputState">
                <span class="user">{{ username }}@{{ $t('terminal.host') }}:/{{ (username === 'root') ? '#' : '$'}}</span>
                <label>
                    <input autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" type="text" ref="input"
                           v-on:keyup.enter="processCommand()"
                           v-on:keyup.up="restoreHistory(1)"
                           v-on:keyup.down="restoreHistory(-1)" />
                </label>
            </div>
        </div>

    </div>
</template>

<script>
    import eventBus from '../eventBus'
    export default {
        name: "Terminal",
        data() {
            return {
                username: this.$t('terminal.guest'),
                state: '',
                history: [],
                historyIndex: 0,
                lastVisit : false,
                disableTransition : 'no-transition',
                inputState: '',
                stopTyping: false,
            }
        },
        methods: {
            focusInput() {
                if( !this.$refs.input ) return;
                this.$refs.input.focus();
            },
            processCommand(command = '') {
                if (this.inputState === 'disabled') return;

                if (command === '') {
                    command = this.$refs.input.value;

                    //push history
                    if (this.history[this.history.length - 1] !== command) this.history.push(command);
                    this.history = this.history.slice(0, 50); //keep only last 50 commands
                    this.historyIndex = this.history.length;

                    //clear input
                    this.$refs.input.value = "";
                }

                const separator = command.indexOf(' ');
                const app = (separator === -1) ? command : command.substr(0, separator);
                const args = (separator === -1) ? '' : command.substr(separator + 1);

                let section;
                switch (app) {

                    case 'help': {
                        section = this.$refs.commandHelp;
                        break;
                    }

                    case 'eval': {
                        section = this.$refs.commandEval;
                        this.$refs.commandEvalP.innerHTML = eval(args);
                        break;
                    }

                    case 'su': {
                        section = this.$refs.commandEval;
                        this.$refs.commandEvalP.innerHTML = (this.username === 'root') ? this.$t('terminal.su.already') : this.$t('terminal.su.now');
                        this.username = 'root';
                        break;
                    }

                    case 'whoami': {
                        section = this.$refs.commandEval;
                        this.$refs.commandEvalP.innerHTML = this.username;
                        break;
                    }

                    case 'clear': {
                        section = this.$refs.commandEval;
                        this.$refs.commandEvalP.innerHTML = '';
                        break;
                    }

                    default: {
                        section = this.$refs.commandError;
                    }
                }
                this.type(section, this.$refs.current);
            },
            restoreHistory(shift) {
                this.historyIndex -= shift;
                this.historyIndex = Math.max(this.historyIndex, 0);
                this.historyIndex = Math.min(this.historyIndex, this.history.length - 1);
                if(this.history[this.historyIndex] === undefined) return;
                this.$refs.input.value = this.history[this.historyIndex];
            },
            toggleState(state) {
                this.state = (this.state === state ? '' : state );
                if(this.state !== 'closed') localStorage.setItem('terminalState', this.state);
            },
            async type(from, to, delay = 16) {
                const source = (from instanceof HTMLElement) ? from : document.querySelector(from);
                const target = (to instanceof HTMLElement) ? to : document.querySelector(to);
                if (!source || !target || this.inputState === 'disabled') return;
                this.inputState = 'disabled';

                const text = source.innerHTML;

                const sleep = (ms) => {
                    return new Promise(resolve => setTimeout(resolve, ms));
                };

                let isTag = false;
                let subtext = '';

                for (let i = 0; i < text.length; i++) {
                    if(this.stopTyping) break;

                    subtext = text.slice(0, i);
                    target.innerHTML = subtext;

                    const char = subtext.slice(-1);
                    if (char === '<') isTag = true;
                    else if (char === '>') isTag = false;

                    if (!isTag) await sleep(delay);
                }

                this.inputState = '';
            },
            reload() {
                this.stopTyping = true;
                setTimeout((that) => {
                    this.stopTyping = false;
                    (that.historyIndex > 0) ? that.processCommand(that.history[that.historyIndex - 1]) : that.type(that.$refs.welcome, that.$refs.current);
                }, 100, this);
            }
        },
        mounted() {
            this.state = localStorage.getItem('terminalState');
            this.lastVisit = new Date( localStorage.getItem('terminalLastVisit') );
            localStorage.setItem('terminalLastVisit', new Date().toUTCString());
            setTimeout(() => {
                this.disableTransition = '';
                this.type(this.$refs.welcome, this.$refs.current);
                this.focusInput();
            }, 100);

            eventBus.$on('language', this.reload);
        }
    };
</script>

<style lang="scss" scoped>
    $terminalBackground: #010101;

    .terminal {
        background: $terminalBackground;
        width: 100%;
        min-width: 250px;
        height: 100%;
        padding: 0.5em 1em;
        transition: 0.3s ease;
        font-weight: 500;
        min-height: 300px;

        @include media-breakpoint-up(lg) {
            height: 50%;
        }

        &.no-transition {
            transition: unset !important;
        }

        &.maximised {
            height: 50%; //actually minimized on mobile;

            @include media-breakpoint-up(lg) {
                height: calc(95% - 2em);
            }
        }

        &.tray {
            height: 2.5em;
            overflow: hidden;
            width: 35%;
            margin-top: 75%;
            cursor: default;
            min-height: 0;

            @include media-breakpoint-up(lg) {
                margin-top: 125%;
            }

            &:hover .title {
                color: #c3c5c7;
            }

            .title {
                cursor: pointer;
            }

            .bar {
                padding: 0;
                border: unset;
            }

            .icons,
            .content {
                transition: 0;
                display: none !important;
            }
        }

        &.closed {
            height: 0;
            min-height: 0;
            width: 0;
            min-width: 0;
            margin-left: 50%;
            padding: 0;
            overflow: hidden;
        }

        h3,
        h4,
        h5,
        h6,
        p,
        span,
        ul,
        li {
            color: #ababb5;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        li {
            margin: 1em 0 0;
        }

        a {
            color: #c2c4c6;
        }

        ::selection {
            background: #fff !important;
            color: #fff;
        }

        .bar {
            display: flex;
            flex-direction: row;
            padding-bottom: 0.5em;
            border-bottom: 1px solid $yellow;

            .title {
                transition: 0.3s ease;
            }

            .icons {
                margin: auto 1em auto 0;
                display: flex;
                flex-direction: row;

                .icon {
                    display: block;
                    height: 0.75em;
                    width: 0.75em;
                    border-radius: 50%;
                    margin-right: 0.5em;
                    transition: 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        filter: opacity(0.8);
                        transition: 0.5s ease-out;
                    }

                    &.icon-closed {
                        background: #f45d58;
                    }

                    &.icon-maximised {
                        background: #edbb45;
                    }

                    &.icon-tray {
                        background: #38c35c;
                    }
                }
            }
        }

        .content {
            padding-top: 0.5em;
            transition: 0.3s ease;
            height: calc(100% - 2em);
            overflow-y: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: $terminalBackground;
            }

            &::-webkit-scrollbar {
                width: 12px;
                background-color: $gray;
            }

            &::-webkit-scrollbar-thumb {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: $yellow;
            }

            section {
                display: none;

                &.open {
                    display: block;
                }

                a:hover,
                a:focus {
                    background: #181818;
                    color: #fff;
                }

                h2 {
                    font-size: 1em;
                    font-weight: 400;
                }

                p {
                    margin-bottom: 2em;
                }

                .cursor {
                    color: #999;
                    line-height: 1;
                }
            }
        }

        .command {
            width: 100%;
            margin: 1em 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            transition: opacity 0.3s ease;

            &.disabled {
                opacity: 0.7;
                pointer-events: none !important;
            }

            .user {
                color: $yellow;
                margin-right: 0.5rem;
            }

            label {
                flex: 1;
                display: flex;
                margin: 0;
                min-width: 50px;
            }

            input {
                background: $terminalBackground;
                width: 100%;
                border: none;
                font-family: inherit;
                color: $yellow;

                &:focus,
                &:active,
                &:hover {
                    outline: none;
                }
            }
        }
    }
</style>
<style lang="scss">
    kbd {
        border: 1px solid $yellow;
        text-transform: uppercase;
        padding: 0.1em 0.75em;
        border-radius: 0;
        background: transparent;
    }
</style>
