<template>
    <div class="home">
        <div class="container d-flex flex-column flex-lg-row">
            <div class="about d-lg-flex flex-lg-column">
                <div>
                    <h1>
                        {{ $t('me.name') }} <span>{{ $t('me.surname') }}</span>
                    </h1>
                    <h2>{{ $t('me.position') }}</h2>
                </div>
            </div>
            <div class="terminal-wrapper d-flex flex-column flex-lg-column">
                <Terminal />
            </div>
        </div>
    </div>
</template>

<script>
    import Terminal from "../components/Terminal";

    export default {
        name: "Home",
        components: {
            Terminal
        }
    };
</script>

<style lang="scss" scoped>
    .home {
        height: 100%;
        overflow-x: hidden;
        padding: 1rem 1rem 0 1rem;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        .container {
            height: 100%;

            @include media-breakpoint-up(lg) {
                .about,
                .terminal-wrapper {
                    width: 50%;
                    height: 100%;
                    justify-content: center;
                }
            }

            .about {
                h1 {
                    font-size: 4rem;
                    color: $gray;

                    span {
                        font-weight: 700;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: 6rem;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 7rem;
                    }

                    @media(min-width: 1800px) {
                        font-size: 9rem;
                    }
                }

                h2 {
                    font-size: 1.25rem;

                    @include media-breakpoint-up(md) {
                        font-size: 1.5rem;
                    }
                }
            }

            .terminal-wrapper {
                justify-content: center;
                background-color: $yellow;
                position: relative;
                padding: 1rem;
                margin: 1rem 0;
                flex-grow: 1;
                max-height: 500px;

                @include media-breakpoint-down(xs) {
                    margin: 0.5rem -0.5rem;
                    padding: 0.5rem;
                }

                @include media-breakpoint-up(lg) {
                    flex-grow: unset;
                    margin: 0;
                    padding: 1rem 0 0 2rem;
                    max-height: unset;

                    &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        height: 100%;
                        width: 50vw;
                        z-index: 1;
                        top: 0;
                        background-color: $yellow;
                        margin-left: calc(100% - 2rem);
                    }
                }
            }
        }
    }
</style>
