<template>
    <div id="app">
        <Navbar />
        <main>
            <vue-page-transition name="fade">
                <router-view />
            </vue-page-transition>
        </main>
        <ScrollBack />
        <Footer />
    </div>
</template>

<script>
    import './assets/scss/_bootstrap.scss';

    import Navbar from "./components/Nabvar";
    import Footer from "./components/Footer";
    import ScrollBack from "./components/ScrollBack";

    export default {
        components: {
            ScrollBack,
            Navbar,
            Footer
        }
    };
</script>

<style lang="scss">
    @import "src/assets/scss/fonts";

    body {
        margin: 0;
        min-height: 100vh;
    }

    @media (min-width: 1800px) {
        .container {
            max-width: 1500px;
        }
    }

    #app {
        font-family: 'Inconsolata', monospace;
        font-size: 16px;
        letter-spacing: 1px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    main {
        display: flex;
        flex-grow: 1;
        max-width: 100vw;
        overflow-x: hidden;

        > div {
            width: 100%;
        }
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
